
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import L, { Icon } from "leaflet";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import _ from "lodash";

@Options({
  components: {},
})
export default class Detail extends Vue {
  store = useStore()
  doc: any = {
    areas: []
  };
  slide: number = 0
  mymap: any;
  layer: any;
  zoom = 17;
  filesArray: Array<Object> = []
  ADDRESS = ADDRESS;

  get thai() {
    return this.store.getters.thai
  }

  fetchData() {
    axios
      .get(`${ADDRESS}/heritage/get/${this.$route.params.id}`)
      .then((response) => {
        console.log("response", response.data);
        this.doc = response.data.result;
        this.filesArray = this.doc.uploadItems
        // try {
        //   this.doc.summary = this.doc.summary.replace("dcp221.jmandjm.com","ich-thailand.org")
        // } catch(error){

        // }
        //https://ich-thailand.org/
        this.initMap();
        this.renderMap();
      });

  }

  resolveLevel(data: Array<string> | string): string {
    if (typeof data === "string")
      return data
    else
      return data.join(", ")
  }

  initMap() {
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }

  getFirstPhoto(items: Array<any>) {
    try {
      for (const item of items) {
        let split = item.path.toLowerCase().split(".");
        let ext: string = split[split.length - 1];
        if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
          return item.path;
        }
      }
    } catch (error) {
      return "n/a";
    }
    return "n/a";
  }
  renderMap() {
    const coordinates = [
      (this.doc.location ?? { coordinates: [100.5708937, 13.7670538] })
        .coordinates[0] ?? 100.5708937,
      (this.doc.location ?? { coordinates: [100.5708937, 13.7670538] })
        .coordinates[1] ?? 13.7670538,
    ];
    var mymap = L.map("map").setView(
      [coordinates[1], coordinates[0]],
      this.zoom
    );
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mymap);
    var greenIcon = L.icon({
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
    });

    var layerGroup = L.layerGroup().addTo(mymap);
    this.layer = layerGroup;
    this.mymap = mymap;
    this.markerRender();
    mymap.on("zoomend", () => {
      this.zoom = mymap.getZoom();
      this.markerRender();
    });
    let self = this;
    mymap.on("click", (e: any) => {
      this.doc.location.coordinates = [e.latlng.lng, e.latlng.lat];
      this.markerRender();
      // var popLocation= e.latlng;
      // var popup = L.popup()
      // .setLatLng(popLocation)
      // .setContent('<p>Hello world!<br />This is a nice popup.</p>')
      // .openOn(mymap);
    });
  }
  markerRender() {
    const coordinates = [
      (this.doc.location ?? { coordinates: [100.5708937, 13.7670538] })
        .coordinates[0] ?? 100.5708937,
      (this.doc.location ?? { coordinates: [100.5708937, 13.7670538] })
        .coordinates[1] ?? 13.7670538,
    ];
    this.layer.clearLayers();
    let content =
      `<div class="my-map-marker">` +
      (this.mymap.getZoom() >= 8
        ? `<span class="title">${this.doc.name}</span>`
        : "");
    L.marker([coordinates[1], coordinates[0]], {
      icon: new L.DivIcon({
        className: "my-div-icon",
        html: content,
      }),
    }).addTo(this.layer);
  }
  getNamefile(path: string) {
    let name = _.split(path, "_")[1];
    return name
  }
  getSurName(path: string) {
    let name = _.split(path, ".")[1];
    return name
  }


  get filesArrayFile() {
    let ArrayFile: Array<Object> = []
    this.filesArray.forEach((element: any) => {
      console.log(element)
      if (element.path.search('.xlsx') > -1 || element.path.search('.docx') > -1 || element.path.search('.ppt') > -1 || element.path.search('.pdf') > -1) ArrayFile.push(element)
    });
    console.log(ArrayFile)
    return ArrayFile
  }
  mounted() {
    this.fetchData();
  }
  resolveBranch(n: string) {
    switch (n) {
      case "1":
        return "Performing arts"
        break;
      case "2":
        return "Traditional craftsmanship"
        break;
      case "3":
        return "Folk literature"
        break;
      case "4":
        return "Folk sports, games and martial arts"
        break;
      case "5":
        return "SOCIAL PRACTICES, RITUALS AND FESTIVE EVENTS"
        break;
      case "6":
        return "Knowledge and practices concerning nature and the universe"
        break;
      case "7":
        return "Languages"
        break;
      default:
        return n
        break;
    }
  }
  resolveType(n: string) {
    switch (n) {
      case "1":
        return "Performing arts"
        break;
      case "2":
        return "Music"
        break;
      case "3":
        return "Folk singing"
        break;
      default:
        return n
        break;
    }
  }
  resolveLevel2(n: string) {
    switch (n) {
      case "1":
        return "Province"
        break;
      case "2":
        return "National"
        break;
      case "3":
        return "UNESCO"
        break;
      default:
        return n
        break;
    }
  }
  resolveStatus(n: string) {
    switch (n) {
      case "1":
        return "Extensively"
        break;
      case "2":
        return "เสี่ยงต่อการสูญหาย"
        break;
      case "3":
        return "ไม่มีปฏิบัติแล้ว"
        break;
      default:
        return n
        break;
    }
  }
}
