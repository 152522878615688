
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import ADDRESS from "@/settings";
import { DateTime } from "luxon";
import { confirmAction, time } from "@/decorators";
import { useStore } from "vuex";
@Options({
  components: {},
})
export default class MediaList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  glances: Array<any> = [];
  page = 1;
  show = 100;
  status: string = "รายการทั้งหมด";
  // total: number = 0;
  // show: number = 100;
  // page: number = 1;
  // pages: number = 0;
  // search:string = ""
  // heritages:Array<any> = []
  filter1: any = {};
  filter2: string | undefined = undefined;
  search: string = "";
  showAdvance: boolean = false;
  showDetail: boolean = false;
  get currentYear() {
    return new Date().getFullYear() + 543;
  }

  getName(item: any) {
    try {
      return `${item.fromMember.firstName} ${item.fromMember.lastName}`;
    } catch (error) {
      return item.name;
    }
  }
  getEmail(item: any) {
    try {
      return `${item.fromMember.email}`;
    } catch (error) {
      return item.email;
    }
  }
  getEmail2(item: any) {
    try {
      return `${item.from.email}`;
    } catch (error) {
      return item.email;
    }
  }
  getNameTo(item: any) {
    try {
      return `${item.to.firstName} ${item.to.lastName}`;
    } catch (error) {
      return item.name;
    }
  }
  getEmailTo(item: any) {
    try {
      return `${item.to.email}`;
    } catch (error) {
      return item.email;
    }
  }
  countstatus(status: string) {
    try {
      if (status == "รายการทั้งหมด") return this.glances.map((el:any)=>el.count).reduce((a,b)=>a+b,0);
      else return this.glances.find((item) => item._id == status).count;
    } catch (error) {
      return 0;
    }
  }
  fetchData() {
    let query = {
      search: {
        ...this.filter1,
        type: this.filter2,
        $or: [
          { name: { $regex: this.search } },
          { code: { $regex: this.search } },
        ],
      },
      page: this.page,
      limit: this.show,
      sort: {modifiedAt: -1 },
      populate: "items",
    };
    if (this.status == "รายการทั้งหมด") {
    } else {
      query.search["$expr"] = {
        $eq: [{ $last: "$answers.direction" }, this.status],
      };
    }
    console.log(this.filter1, query);
    axios.post(`${ADDRESS}/contacts/paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.docs = this.docs.map(doc=>{
        let question = doc.question.replace(/<img src=(?:[^'\/]*\/)*([^']+)>/g, "*รูปภาพ*");
        return {
          ...doc,
          question
        }
      })
      this.glances = response.data.glances
    });
  }
  mounted() {
    this.fetchData();
  }
}
