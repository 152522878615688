// let ADDRESS = "https://dcp221.jmandjm.com/api/v1"
let ADDRESS = "https://ich-thailand.org/api/v1"
// let ADDRESS = "http://192.168.1.117:22110/api/v1" // ATS 
// let ADDRESS = "http://192.168.68.125:22110/api/v1"
// let ADDRESS = "http://localhost:22110/api/v1"
// let ADDRESS = "http://192.168.1.7:22110/api/v1" // JTM
// let ADDRESS = "http://192.168.1.31:22110/api/v1" //GoodHands

// export const SOCKET_ADDRESS = "ws://localhost:22111" // Local Development Server
export const SOCKET_ADDRESS = "wss://dcp221.jmandjm.com" // JM Development Server
// export const SOCKET_ADDRESS = "ws://dcp221.jmandjm.com:22111" // JM Development Server

export const APIKEY = "2f66df29a833f9c87023ee0bb728531d"
export let filePath = ADDRESS.replace("api/v1", "api/v1");
export default ADDRESS